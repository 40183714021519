import React, { useCallback, useEffect, useState } from 'react'
import {
  CookieConsentProvider,
  useCookieConsent,
} from '@scaleway/cookie-consent'
import { useSegment } from '@scaleway/use-segment'
import { getOrganizationIdFromCookie } from '../helpers/cookies'
import { hasConsentedAnalytics } from '../helpers/analytics'

const Consents = React.createContext()

const setAllConsents = ({ categoriesConsent, value }) =>
  Object.keys(categoriesConsent).reduce(
    (acc, category) => ({ ...acc, [category]: value }),
    {},
  )

export const useConsents = () => {
  const { events } = useSegment()
  const { saveConsent, categoriesConsent } = useCookieConsent()

  const [consents, setConsents] = useState({})

  const updateConsents = useCallback(
    newConsents => {
      setConsents(newConsents)
      saveConsent(newConsents)
    },
    [setConsents, saveConsent],
  )

  const trackPage = useCallback(() => {
    if (hasConsentedAnalytics() && events) {
      window.analytics.page({
        page_type: window.location.pathname.startsWith('/en/docs')
          ? 'Docs'
          : 'Developers',
        title: window.document.title,
        userId: getOrganizationIdFromCookie(),
      })
    }
  }, [events])

  const handleClick = useCallback(
    consentForAll => () =>
      updateConsents(
        setAllConsents({ categoriesConsent, value: consentForAll }),
      ),
    [categoriesConsent, updateConsents],
  )

  const acceptAll = useCallback(() => {
    handleClick(true)()
    trackPage()
  }, [handleClick, trackPage])

  const refuseAll = handleClick(false)

  const validateChoices = useCallback(() => {
    updateConsents({ ...consents, functional: true })
    trackPage()
  }, [consents, updateConsents, trackPage])

  const toggleChoices = useCallback(
    ({ target: { checked } }, children) => {
      const key = children.toLowerCase()
      setConsents({
        ...consents,
        [key]: checked,
      })
    },
    [consents, setConsents],
  )

  useEffect(() => {
    setConsents(categoriesConsent)
  }, [categoriesConsent])

  return {
    acceptAll,
    consents,
    refuseAll,
    toggleChoices,
    updateConsents,
    validateChoices,
  }
}

export const ConsentsProvider = ({ children }) => {
  const segment = process.env.GATSBY_SEGMENT_KEY
    ? {
        writeKey: process.env.GATSBY_SEGMENT_KEY,
        cdnURL: 'https://evs.nuage01.scaleway.com',
      }
    : undefined

  return (
    <CookieConsentProvider
      config={{
        segment,
      }}
      essentialIntegrations={[]}
      isConsentRequired
    >
      {children}
    </CookieConsentProvider>
  )
}

export default Consents
