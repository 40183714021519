import { useEffect, useState } from 'react'
import { Global, ThemeProvider } from '@emotion/react'
import {
  consoleDarkerTheme as darkTheme,
  consoleLightTheme as lightTheme,
} from '@ultraviolet/themes'
import { useColorMode } from '@xstyled/styled-components'
import inter400 from '@fontsource/inter/latin-400.css'
import inter500 from '@fontsource/inter/latin-500.css'
import inter600 from '@fontsource/inter/latin-600.css'
import { isEmbeddedDarkMode } from '../helpers/embedded'

/*
  This is a PATCH of theme in order to add recently added keys to the theme object.
  We can't update Ultraviolet Theme on documentation projects because it will break the build and add a lot of regression.
  Only solution for now to be able to continue updating productIcons and other components that use theme object.
 */
const sizing = {
  0: '0rem',
  '012': '0.0625rem',
  '025': '0.125rem',
  '050': '0.25rem',
  100: '0.5rem',
  125: '0.625rem',
  150: '0.75rem',
  175: '0.875rem',
  200: '1rem',
  250: '1.25rem',
  262: '1.3125rem',
  300: '1.5rem',
  312: '1.5625rem',
  400: '2rem',
  437: '2.1875rem',
  500: '2.5rem',
  600: '3rem',
  700: '3.5rem',
  750: '3.75rem',
  800: '4rem',
  900: '4.5rem',
  1000: '5rem',
}

const themes = {
  default: { ...lightTheme, sizing },
  _dark: { ...darkTheme, sizing },
}

export function SwThemeProvider({ children }) {
  const [colorMode, setColorMode] = useColorMode()
  const [theme, setTheme] = useState(themes.default)

  useEffect(() => {
    const isForcedDarkMode = isEmbeddedDarkMode()
    if (isForcedDarkMode) {
      setColorMode('_dark')
      setTheme(themes['_dark'])
    } else {
      // eslint-disable-next-line no-underscore-dangle
      setTheme(colorMode === 'default' ? themes.default : themes._dark)
    }
  }, [colorMode, setColorMode])

  return (
    <>
      <Global styles={[inter400, inter500, inter600]} />
      <ThemeProvider theme={{ ...theme, setTheme }}>{children}</ThemeProvider>
    </>
  )
}
