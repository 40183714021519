import {
  ColorModeProvider,
  Preflight,
  ThemeProvider,
} from '@xstyled/styled-components'
import theme from '@documentations/commons/styles/theme'
import GlobalStyle from '@documentations/commons/styles/GlobalStyle'

import { SwThemeProvider } from '../contexts/SwTheme'
import { SearchProvider } from '../contexts/Search'

import { ConsentsProvider } from '../contexts/Consents'
import { ThirdPartyProviders } from '../contexts/ThirdParty'

export const CommonsProvider = ({ children }) => (
  <>
    <Preflight />
    <ThemeProvider theme={theme}>
      <ColorModeProvider>
        <SwThemeProvider>
          <ConsentsProvider>
            <ThirdPartyProviders>
              <SearchProvider>
                <GlobalStyle />
                {children}
              </SearchProvider>
            </ThirdPartyProviders>
          </ConsentsProvider>
        </SwThemeProvider>
      </ColorModeProvider>
    </ThemeProvider>
  </>
)
